import React from "react";

export default function Header404() {
  return (
    <header className="main-header">
      <div className="main-logo">
        <img src="img/main-logo.svg" />
      </div>
      <div className="height-corrector"></div>
    </header>
  );
}
