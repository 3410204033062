import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';
import '../admin-finance/adminfinance.css';
import NumberFilter from '@inovua/reactdatagrid-community/NumberFilter';
import DateFilter from '@inovua/reactdatagrid-community/DateFilter';
import moment from 'moment';
window.moment = moment;

const URL_API_ADMIN_FINANCE = 'https://partner-system.ru/api/admin/transactions/';
const ACCESS_TOKEN_USER = localStorage.getItem('ACCESS_TOKEN');

const filterValue = [
  { name: 'user_email', operator: 'startsWith', type: 'string', value: ''},
  { name: 'number_of_points', operator: 'eq', type: 'number', value: null },
  { name: 'date', operator: 'inrange', type: 'date', value: ''}
];

const columns = [
  { name: 'user_email', header: 'Почта', minWidth: 250, maxWidth: 350 },
  {
    name: 'number_of_points',
    header: 'Количество баллов',
    defaultFlex: 1, maxWidth: 200,
    type: 'number',
    filterEditor: NumberFilter
  },
  { name: 'sender_wallet', header: 'Кошелек отправки', defaultFlex: 1,  maxWidth: 250 },
  { name: 'recipient_wallet', header: 'Кошелек пользователя', defaultFlex: 1, maxWidth: 250, operator: 'startsWith' },
  {
    name: 'date',
    header: 'Дата',
    maxWidth: 350,
    defaultFlex: 1,
    filterEditor: DateFilter,
    filterEditorProps: () => {
      return {
        dateFormat: 'YYYY-MM-DD',
        cancelButton: false,
        highlightWeekends: false,
      }
    },
    render: ({ value, cellProps }) => {
      return moment(value).format('DD-MM-YYYY')
    },
  },
  { name: 'status', header: 'Status'}
];

const gridStyle = {
  minHeight: 600,
  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.25), 0px -4px 10px rgba(0, 0, 0, 0.25)',
  marginTop: '20px',
  color: 'white',
  height: '300px',
  backgroundColor: '#2B3243',
  borderRadius: '8px',
  textAlign: 'center',
 }

export default function AdminFinance() {
  const [dataSource, setDataSource] = useState<Array<any>>([]);

  useEffect(() => {
    fetchData();
  }, [])

  const fetchData = async () => {
    try {
      const response = await axios.get(URL_API_ADMIN_FINANCE, {
        headers: {
          'Authorization': `Bearer ${ACCESS_TOKEN_USER}`
        }
      });
      const res = response.data;
      setDataSource(res);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <ReactDataGrid
        idProperty="id"
        columns={columns}
        dataSource={dataSource}
        style={gridStyle}
        defaultFilterValue={filterValue}
        pagination
        defaultLimit={10}
      />
    </div>
  )
}
