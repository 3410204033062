import React, { useState, useRef, MutableRefObject } from "react";
import { createPortal } from "react-dom";
import getPoint from '../../helpers/get-point/get-point';
import styled from 'styled-components';

type ShowProp = {
  show: number,
  posref: MutableRefObject<{x: number, y: number}>
}

const StyledTooltip = styled.span<ShowProp>`
  position: fixed;
  top: ${(p: ShowProp) => p.posref?.current.y}px;
  left: ${(p: ShowProp) => p.posref?.current.x}px;
  z-index: 99999;
  display: inline-block;
  padding: 3px;
  font-size: 10px;
  font-weight: 700;
  letter-spacing: 0.02em;
  color: black;
  background-color: #FEFFC0;
  pointer-events: none;
  border-radius: 4px;
  white-space: nowrap;
  opacity: ${(p: ShowProp) => p.show};
  transition: opacity .7s;
`;

export default function Tooltip({ text, placement = 'bottom', space = 12, children }):JSX.Element {
  const [show, setShow] = useState(0);

  const posRef = useRef<{ x: number, y: number }>({x: 0, y: 0});

  const tooltipRef = useRef<HTMLSpanElement>(null);

  const handleMouseOver = (e: CustomEvent) => {
    setShow(1);

    posRef.current = getPoint(e.currentTarget, tooltipRef.current, placement, space)
  };

  const handleMouseOut = () => setShow(0);

  return (
    <>
      {React.cloneElement(children, {
        onMouseOver: handleMouseOver,
        onMouseOut: handleMouseOut
      })}
      <Portal>
        <StyledTooltip ref={tooltipRef} posref={posRef} show={show}>{text}</StyledTooltip>
      </Portal>
    </>
  )
}

function Portal({ children }) {
  return createPortal(children, document.body);
}

