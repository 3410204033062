import React, {Fragment} from "react";
import './style-404.css'
import {Link} from "react-router-dom";
import Header404 from "../../components/404-header/404-header";

export default function Page404() {
  return (
    <Fragment>
      <Header404 />
      <section className='block-404'>
        <div className='title-404'>
          <span>Error 404</span>
        </div>
        <div className='text-404__wrapper'>
          <p className='text-404'>
            Page not found...
          </p>
        </div>
        <Link to='/' className='main-page-link'>
          <span>Click here to return the main page</span>
        </Link>
      </section>
    </Fragment>
  );
}
