import React, { useState, useEffect } from 'react'
import './partnercode.css'
import ModalCreatePartnerCode from "./ModalCreatePartnerCode";
import ModalPartnerCodeOk from './ModalPartnerCodeOk';
import axios from 'axios';

const URL_API_PROMOCODES = 'https://partner-system.ru/api/promocodes/me/';
const ACCESS_TOKEN_USER = localStorage.getItem('ACCESS_TOKEN');
const dayjs = require('dayjs');

export default function PartnerCode() {
    const [showModalCreatePartnerCode, setShowModalCreatePartnerCode] = useState(false);
    const [showModalPartnerCodeOk, setShowModalPartnerCodeOk] = useState(false);
    const [data, setData] = useState<Array<any>>([]);


    useEffect(() => {
        fetchData();
      }, [])

      const fetchData = () => {
        axios
        .get(URL_API_PROMOCODES, {
          headers: {
            'Authorization': `Bearer ${ACCESS_TOKEN_USER}`
          }
        })
        .then((response) => {
          setData(response.data);
        })
        .catch((e) => {
          console.error(e);
        })
      }

    const handleButtonClick = () => {
        setShowModalCreatePartnerCode(true);
        document.body.classList.add('modal-open');
    }

    const handleButtonClickModal = () => {
      setShowModalPartnerCodeOk(true);
      setTimeout(() => setShowModalPartnerCodeOk(false), 1500);
    }

  return (
    <div>
        <div className='partner-code-wrap partner-code'>
            <table className='partner-code__table'>
                <thead className='partner-code__table-head'>
                    <tr className='partner-code__table-row'>
                        <th scope='col' className='partner-code__table-cell-head-th'>Название</th>
                        <th scope='col' className='partner-code__table-cell-head-th'>Активаций</th>
                        <th scope='col' className='partner-code__table-cell-head-th'>Дата создания</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((item, index) => (
                      <tr key={index} className='partner-code__table-row'>
                        <td className='partner-code__table-cell'>{item.name}</td>
                        <td className='partner-code__table-cell'>{item.number_of_activations}</td>
                        <td className='partner-code__table-cell'>{dayjs(item.creation_date).format('DD/MM/YYYY')}</td>
                      </tr>
                    ))}
                </tbody>
            </table>
        </div>
        <button className='partner-code__button-create-code' onClick={handleButtonClick}>Создать промокод парнёра</button>
        {showModalCreatePartnerCode && <ModalCreatePartnerCode onClose={()=> setShowModalCreatePartnerCode(false)} onOpen={handleButtonClickModal}/>}
        {showModalPartnerCodeOk && <ModalPartnerCodeOk onClose={() => setShowModalPartnerCodeOk(false)}/>}
    </div>
  )
}
