import React from 'react';
import './partnercode.css';

export default function ModalPartnerCodeOk({ onClose }) {

    const handleOverlayClick = (event: React.MouseEvent<HTMLDivElement>) => {
        if (event.target === event.currentTarget) {
            onClose();
        }
    };

  return (
    <div className='modal-overlay' onClick={handleOverlayClick}>
        <div className='modal-promocode-ok'>
        <div className='modal-promocode-ok__header'>
          <span className='modal-promocode-ok__header-text'>Промокод создан успешно</span>
        </div>
        <img className='modal-promocode-ok__image' src='img/iconOk.svg' alt='ok' />
        </div>
    </div>
  )
}
