import React, { useState } from 'react';
import './partnercode.css';
import axios from 'axios';

type ModalCreatePartnerCodeProps = {
  onClose: () => void;
  onOpen: () => void;
}

const URL_API_POST_PROMOCODES = 'https://partner-system.ru/api/promocodes/';
const URL_API_POST_PROMOCODES_CHECK = 'https://partner-system.ru/api/promocodes/check/';
const ACCESS_TOKEN_USER = localStorage.getItem('ACCESS_TOKEN');


export default function ModalCreatePartnerCode({ onClose, onOpen }: ModalCreatePartnerCodeProps) {
  const [modalInputCreatePartnerCode, setModalInputCreatePartnerCode] = useState('');
  const [modalInputCreatePartnerCodeDirty, setModalInputCreatePartnerCodeDirty] = useState(false);
  const [modalInputCreatePartnerCodeError, setModalInputCreatePartnerCodeError] = useState('');
  const [modalInputValid, setModalInputValid] = useState(false);
  const [verifyPromo, setVerifyPromo] = useState('');

  const fetchPromocodes = () => {
    axios
    .post(URL_API_POST_PROMOCODES, {name: modalInputCreatePartnerCode}, {
      headers: {
        'Authorization': `Bearer ${ACCESS_TOKEN_USER}`
      },
    })
    .then((response) => {
    })
    .catch((e) => {
      console.error(e);
    })
  }

  const handleButtonClick = () => {
      fetchPromocodes();
      onClose();
      setModalInputValid(false);
  }

  const handleOverlayClick = (event: React.MouseEvent<HTMLDivElement>) => {
      if (event.target === event.currentTarget) {
          onClose();
          setModalInputValid(false);
      }
  };

  const partnerCodeHandler = async (e) => {
    const value = e.target.value;
    setModalInputCreatePartnerCode(value);
    setModalInputCreatePartnerCodeDirty(true);
    const re = /^[а-яА-Яa-zA-Z0-9_]+$/
    if (!re.test(String(value).toLowerCase())) {
      setModalInputCreatePartnerCodeError('Код должен состоять только из букв цифр и знака _');
      setVerifyPromo('');
      setModalInputValid(false);
    } else if (value.length < 5) {
      setModalInputCreatePartnerCodeError('Минимальная длина кода 5 символов');
      setVerifyPromo('');
      setModalInputValid(false);
    } else {
      setModalInputCreatePartnerCodeError('');
      try {
        const response = await axios.post(URL_API_POST_PROMOCODES_CHECK, { name: value }, {
          headers: {
            'Authorization': `Bearer ${ACCESS_TOKEN_USER}`
          },
        });
        setVerifyPromo('промокод свободен');
        setModalInputValid(true);
      } catch (error) {
        console.error(error);
        setVerifyPromo('промокод занят');
        setModalInputValid(false);
      }
    }
  }

  const blurHandler = (e) => {
    if ((e.target.name) === 'partnerCode') {
        setModalInputCreatePartnerCodeDirty(true);
    }
  }

  return (
    <div className='modal-overlay' onClick={handleOverlayClick}>
        <div className='modal_create_partner_code'>
            <span className="modal_create_partner_code_close" onClick={handleButtonClick}>✖</span>
            <span className='modal_create_partner_code-header'>Придумайте промокод партнёра</span>
            <form className='modal_create_partner_code-form' onSubmit={handleButtonClick}>
          {modalInputCreatePartnerCodeDirty && modalInputCreatePartnerCodeError && (
            <div className="modal_create_partner_code-input_text_upper red">{modalInputCreatePartnerCodeError}</div>
          )}
            {verifyPromo && <div className={`modal_create_partner_code-input_text_upper ${verifyPromo === 'промокод свободен' ? 'green' : 'red'}`}>{verifyPromo}</div>}
              <input
              onChange={e => partnerCodeHandler(e)}
              value={modalInputCreatePartnerCode}
              onBlur={e => blurHandler(e)}
              name='partnerCode'
              className='modal_create_partner_code-input'
              type='text'
              placeholder='Промокод партнёра мажет состоять только из букв цифр и _'>
              </input>
              <span className='modal_create_partner_code-input_text_bottom'>минимальная длина кода - 5 символов</span>
              <button
              onClick={onOpen}
              disabled={!modalInputValid}
              className='modal_create_partner_code-button'
              >
                Создать
              </button>
            </form>
        </div>

    </div>
  )
}
