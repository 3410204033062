import React, { useState, useEffect } from 'react';
import './main-info.css';
import axios from 'axios';

const GENERAL_INFO_API = 'https://partner-system.ru/api/general/information/';
const ACCESS_TOKEN_USER = localStorage.getItem('ACCESS_TOKEN');

export default function MainInfo() {
  const [data, setData] = useState<Array<any>>([]);
  const [promoCodes, setPromoCodes] = useState<Array<any>>([]);

  useEffect(() => {
    const fetchData = () => {
      axios
        .get(GENERAL_INFO_API, {
          headers: {
            'Authorization': `Bearer ${ACCESS_TOKEN_USER}`
          }
        })
        .then((response) => {
          setData(response.data);

          const promoCodes = response.data.map(item => item.promo_codes);
          setPromoCodes(promoCodes[0]);
        })
        .catch((e) => {
          console.error(e);
        })
    }

    fetchData();
  }, []);

  return (
    <div className='main-info partner-code'>
      <table className='partner-code__table'>
        <thead className='partner-code__table-head'>
          <tr className='partner-code__table-row'>
            <th scope='col' className='partner-code__table-cell-head-th'>Привлечено пользователей</th>
            <th scope='col' className='partner-code__table-cell-head-th'>Мой %</th>
            <th scope='col' className='partner-code__table-cell-head-th'>Активирован промокод</th>
            <th scope='col' className='partner-code__table-cell-head-th'>Количество активаций</th>
            <th scope='col' className='partner-code__table-cell-head-th'>Баллов за месяц</th>
            <th scope='col' className='partner-code__table-cell-head-th'>Всего баллов</th>
            {/* <th scope='col' className='partner-code__table-cell-head-th'>Получено от партнёров</th>
            <th scope='col' className='partner-code__table-cell-head-th'>Привлечено партнёров</th> */}
            <th scope='col' className='partner-code__table-cell-head-th'>Привлечено пользователей</th>
          </tr>
        </thead>
        <tbody className='partner-code__body'>
          {data.length > 0 && data.map((item, index) => (
            <tr key={index} className='partner-code__table-row'>
              <td className='partner-code__table-cell'>{item.attracted_users || '-'}</td>
              <td className='partner-code__table-cell'>{item.percent || '-'}</td>
              <td className='partner-code__table-cell'>{item.promo_codes[0].name || '-'}</td>
              <td className='partner-code__table-cell'>{item.promo_codes[0].number_of_activations || '-'}</td>
              <td className='partner-code__table-cell'>{item.promo_codes[0].points_per_month || '-'}</td>
              <td className='partner-code__table-cell'>{item.promo_codes[0].total_points || '-'}</td>
              {/* <td className='partner-code__table-cell'>{item.promo_codes[0].points_from_partners}</td>
              <td className='partner-code__table-cell'>{item.promo_codes[0].attracted_partners || '-'}</td> */}
              <td className='partner-code__table-cell'>{item.promo_codes[0].attracted_users || '-'}</td>
            </tr>
          ))}

          {promoCodes.slice(1).map((item, index) => (
            <tr key={index} className='partner-code__table-row'>
              <td className='partner-code__table-cell'>{}</td>
              <td className='partner-code__table-cell'>{}</td>
              <td className='partner-code__table-cell'>{item.name || '-'}</td>
              <td className='partner-code__table-cell'>{item.number_of_activations || '-'}</td>
              <td className='partner-code__table-cell'>{item.points_per_month || '-'}</td>
              <td className='partner-code__table-cell'> {item.total_points || '-'}</td>
              {/* <td className='partner-code__table-cell'>{item.points_from_partners || '-'}</td>
              <td className='partner-code__table-cell'>{item.attracted_partners}</td> */}
              <td className='partner-code__table-cell'>{item.attracted_users || '-'}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

