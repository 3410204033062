export default function getPoint(el: any, tt: any, placement: string, space: number) {

  const pt = {x: 0, y: 0}

  const elRect = el.getBoundingClientRect();

  switch(placement) {
    case 'left':
      pt.x = elRect.left - (el.offsetWidth + space);
      pt.y = elRect.top - (el.offsetHeight - tt.offsetHeight) / 2;
      break;
    case 'right':
      pt.x = elRect.right + space;
      pt.y = elRect.top + (el.offsetHeight - tt.offsetHeight) / 2;
      break;
    case 'top':
      pt.x = elRect.left + (el.offsetWidth - tt.offsetWidth) / 2;
      pt.y = elRect.top - (el.offsetHeight + space);
      break;
    default:
      pt.x = elRect.left + (el.offsetWidth - tt.offsetWidth) / 2;
      pt.y = elRect.bottom + space;
  }

  return pt;
}