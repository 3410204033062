import { Fragment, useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import ProfileHeader from "../profile-header/profile-header";
import axios from "axios";
import ProfileModalSuccess from "./profile-modal-success"
import './profile-style.css';
import './main-logo-profile.svg';

const URL_API_PROFILE_GET = 'https://partner-system.ru/api/users/me/';
const URL_API_PROFILE_PUT = 'https://partner-system.ru/api/users/me/';
const ACCESS_TOKEN_PROFILE = localStorage.getItem('ACCESS_TOKEN');

export default function Profile() {

  const [imgURL, setImgURL] = useState('');
  const [userName, setUserName] = useState('');
  const [userFirstname, setUserFirstname] = useState('');
  const [userLastname, setUserLastname] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [userNumber, setUserNumber] = useState('');
  const [userTelegram, setUserTelegram] = useState('');
  const [userWhatsapp, setUserWhatsapp] = useState('');
  const [userAvatar, setUserAvatar] = useState('');

  const [newUserName, setNewUserName] = useState('');
  const [newUserFirstname, setNewUserFirstname] = useState('');
  const [newUserLastname, setNewUserLastname] = useState('');
  const [newUserEmail, setNewUserEmail] = useState('');
  const [newUserNumber, setNewUserNumber] = useState('');
  const [newUserTelegram, setNewUserTelegram] = useState('');
  const [newUserWhatsapp, setNewUserWhatsapp] = useState('');

  const [showModalProfileSuccess, setShowModalProfileSuccess] = useState(false);

  const [nickNameError, setNickNameError] = useState('');
  const [telegramError, setTelegramError] = useState('');
  const [whatsappError, setWhatsappError] = useState('');


  useEffect(() => {
    getProfileInfo();
  }, []);

  const getProfileInfo = () => {
    axios
      .get(URL_API_PROFILE_GET, {
        headers: {
          'Authorization': `Bearer ${ACCESS_TOKEN_PROFILE}`
        }
      })
      .then((response) => {
        const userProfileInfo = response.data;
        const userNameValue = userProfileInfo.username;
        const userFirstnameValue = userProfileInfo.first_name;
        const userLastnameValue = userProfileInfo.last_name;
        const userEmailValue = userProfileInfo.email;
        const userNumberValue = userProfileInfo.phone;
        const userTelegramValue = userProfileInfo.telegram;
        const userWhatsappValue = userProfileInfo.whatsapp
        const userAvatarValue = userProfileInfo.avatar;

        setUserName(userNameValue);
        setUserFirstname(userFirstnameValue);
        setUserLastname(userLastnameValue);
        setUserEmail(userEmailValue);
        setUserNumber(userNumberValue);
        setUserTelegram(userTelegramValue);
        setUserWhatsapp(userWhatsappValue);
        setUserAvatar(userAvatarValue);
    })
    .catch((e) => {
      console.error(e);
    })
  }

  const validateNickname = (value) => {
    const nickNamePattern = /^(?=[a-zA-Z0-9_-]{2,30}$)[a-zA-Z0-9]+(?:[_-][a-zA-Z0-9]+)*$/;
    if (!nickNamePattern.test(value)) {
      setNickNameError('Некорректный никнейм');
    } else {
      setNickNameError('Никнейм');
    }
  }

  const validateTelegram = (value) => {
    const TelegramPattern = /^[a-zA-Z0-9_]{5,}$/;
    if (!TelegramPattern.test(value)) {
      setTelegramError('Некорректный Telegram');
    } else {
      setTelegramError('Telegram');
    }
  }

  const validateWhatsapp = (value) => {
    const WhatsappPattern = /^\+\d+$/;
    if (!WhatsappPattern.test(value)) {
      setWhatsappError('Некорректный WhatsApp');
    } else {
      setWhatsappError('WhatsApp');
    }
  }

  const fileReader = new FileReader();
  fileReader.onloadend = () => {
    if (fileReader.result !== null) {
      setImgURL(fileReader.result as string);
    }
  };

  const handleOnChange = (event) => {
    event.preventDefault();
    const { target } = event;
    if (target.type === 'file') {
      const file = event.target.files[0];
      setImgURL(file);
      fileReader.readAsDataURL(file);
    }
  }

  const userInfoPatch = {
    username: newUserName ? newUserName: userName,
    first_name: newUserFirstname ? newUserFirstname : userFirstname,
    last_name: newUserLastname ? newUserLastname : userLastname,
    email: newUserEmail ? newUserEmail : userEmail,
    phone: newUserNumber ? newUserNumber : userNumber,
    telegram: newUserTelegram ? newUserTelegram: userTelegram,
    whatsapp: newUserWhatsapp ? newUserWhatsapp : userWhatsapp,
    avatar: imgURL ? imgURL : userAvatar
  };


  const handleButtonClickModal = () => {
    setShowModalProfileSuccess(true);
    setTimeout(() => setShowModalProfileSuccess(false), 1500);
  }

  const putProfileInfo = async (e) => {
    e.preventDefault();

    try {
      const resp = await axios
        .put(URL_API_PROFILE_PUT,
          userInfoPatch,
          {headers: {'Authorization': `Bearer ${ACCESS_TOKEN_PROFILE}`}
      })
      handleButtonClickModal();
      console.log(resp);
    } catch (err) {
        console.error("Error from PUT:", err);
    }
  };

  return (
    <Fragment>
      <ProfileHeader />
      <section className='profile-container'>
        <div className='profile-container__photo'>
          <Link to='/main' className='back'>
            <img src='img/arrow-back.svg' alt='назад'/>
            <span className='back-title'>Назад</span>
          </Link>
        </div>
        <form className='profile-data__form' onSubmit={putProfileInfo} onChange={handleOnChange}>
          <div className='profile-form__wrapper'>
            <div className='profile-container__photo-avatar'>
                <img src={imgURL ? imgURL : userAvatar ? userAvatar : './img/avatar.svg'} className="profile-img" alt='avatar'/>
              <label htmlFor="avatar" className='change-profile-link-label'>Изменить фото</label>
                <input type="file" id="avatar" accept="image/*" className='change-profile-link' name="avatar" />
            </div>
            <div className='profile-container__data'>
              <h2 className="profile-container__data-title">Основные данные</h2>

              <div className='profile-input-group'>
                <input
                  onChange={(e) => setNewUserName(e.target.value)}
                  onInput={(el: React.ChangeEvent<HTMLInputElement>) => {
                    validateNickname(el.target.value);
                  }}
                  defaultValue={userName}
                  type="text"
                  name="username"
                  id="username"
                  placeholder=""
                  autoComplete="off"
                  required
                />
                <label 
                  htmlFor="username"
                  className={`phone_text ${nickNameError === 'Некорректный никнейм' ? 'red' : 'green'}`}
                >
                  {nickNameError || 'Псевдоним'}
                </label>
              </div>

              <div className='profile-input-group'>
                <input
                  onChange={(e) => setNewUserFirstname(e.target.value)}
                  defaultValue={userFirstname}
                  type="text"
                  name="first_name"
                  id="first_name"
                  placeholder=""
                  autoComplete="off"
                  required
                />
                <label htmlFor="first_name">Имя</label>
              </div>

              <div className='profile-input-group'>
                <input
                  onChange={(e) => setNewUserLastname(e.target.value)}
                  defaultValue={userLastname}
                  type="text"
                  name="last_name"
                  id="last_name"
                  placeholder=""
                  autoComplete="off"
                  required
                />
                <label htmlFor="lastname">Фамилия</label>
              </div>

              <div className='profile-input-group'>
                <input
                  onChange={(e) => setNewUserEmail(e.target.value)}
                  defaultValue={userEmail}
                  type='email'
                  name="email"
                  id="email"
                  placeholder=""
                  autoComplete="off"
                  required
                />
                <label htmlFor="email">Email</label>
              </div>

              <div className='profile-input-group'>
                <input
                  onChange={(e) => setNewUserNumber(e.target.value)}
                  defaultValue={userNumber}
                  type='tel'
                  name="tel"
                  id="phone"
                  placeholder=''
                  autoComplete="off"
                  required
                />
                <label htmlFor="phone">Телефон</label>
              </div>

              <button type="submit" className='profile-submit-button'>Сохранить изменения</button>
            </div>
            <div className='profile-container__social'>
              <h2 className="profile-container__data-title">Ссылки на социальные сети</h2>

              <div className='profile-input-group'>
                <input
                  onChange={(e) => setNewUserTelegram(e.target.value)}
                  onInput={(el: React.ChangeEvent<HTMLInputElement>) => {
                    validateTelegram(el.target.value);
                  }}
                  defaultValue={userTelegram}
                  type='text'
                  name="telegram"
                  id="telegram"
                  placeholder=""
                  autoComplete="off"
                  required
                />
                <label 
                  htmlFor="telegram"
                  className={`phone_text ${telegramError === 'Некорректный Telegram' ? 'red' : 'green'}`}
                >
                  {telegramError || 'Telegram'}
                </label>
              </div>

              <div className='profile-input-group'>
                <input
                  onChange={(e) => setNewUserWhatsapp(e.target.value)}
                  onInput={(el: React.ChangeEvent<HTMLInputElement>) => {
                    validateWhatsapp(el.target.value);
                  }}
                  defaultValue={userWhatsapp}
                  type='text'
                  name="whatsapp"
                  id="whatsapp"
                  placeholder=""
                  autoComplete="off"
                  required
                />
                <label 
                  htmlFor="whatsapp"
                  className={`phone_text ${whatsappError === 'Некорректный WhatsApp' ? 'red' : 'green'}`}
                >
                  {whatsappError || 'Whatsapp'}
                </label>
              </div>
              <div className='profile-input-group'>
                <input
                  type='text'
                  name="wallet"
                  id="wallet"
                  placeholder=""
                  autoComplete="off"
                  required
                />
                <label htmlFor="wallet">Номер криптокошелька</label>
              </div>
            </div>
          </div>
        </form>
      </section>
      {showModalProfileSuccess && <ProfileModalSuccess onClose={() => setShowModalProfileSuccess(false)} />}
    </Fragment>
  );
}
