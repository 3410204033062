import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './styleModalOutputBalance.css';
import ModalVerificationCode from '../modalOutputBalance/ModalVerificationCode';
import InstructionOutBalance from './InstructionOutBalance';

type ModalOutputBalanceProps = {
    onClose: () => void;
}

const URL_API_BALANCE = 'https://partner-system.ru/api/general/balance/';
const URL_SEND_VERIFY_CODE = 'https://partner-system.ru/api/sms/send_verify_code/'
const ACCESS_TOKEN_USER = localStorage.getItem('ACCESS_TOKEN');


export default function ModalOutputBalance({ onClose }: ModalOutputBalanceProps) {
    const [value, setValue] = useState('')
    const [data, setData] = useState<Array<any>>([]);
    const [showVerification, setShowVerification] = useState(false);
    const [instruction, setInstruction] = useState(false);

      useEffect(() => {
      const fetchData = () => {
        axios
          .get(URL_API_BALANCE, {
            headers: {
              'Authorization': `Bearer ${ACCESS_TOKEN_USER}`
            }
          })
          .then((response) => {
            const res = response.data;
            const limit = res.map(item => item.limiter)
            setData(limit);
          })
          .catch((e) => {
            console.error(e);
          })
      }

      fetchData();
    }, []);


      const fetchDataVerify = () => {
        axios
          .get(URL_SEND_VERIFY_CODE, {
            headers: {
              'Authorization': `Bearer ${ACCESS_TOKEN_USER}`
            }
          })
          .then((response) => {

          })
          .catch((e) => {
            console.error(e);
          })
      }

    const onChange = ({target: {value}}) =>
        setValue(prev => /^\d+$/.test(value) || value === '' ? value : prev);

    const handleButtonClick = () => {
        setShowVerification(true);
        fetchDataVerify();
    }

    const handleCloseClick = () => {
      onClose();
    }

    const handleOverlayClick = (event: React.MouseEvent<HTMLDivElement>) => {
        if (event.target === event.currentTarget) {
            onClose();
        }
    };

      const isButtonDisabled = Number(value) < Number(data[0]) || isNaN(Number(value));

    const openInstruction = () => {
      setInstruction(true);
    };

    const closeInstruction = () => {
      setInstruction(false);
    }

  return (
    <div className='modal-overlay' onClick={handleOverlayClick}>
      {showVerification ? (
        <ModalVerificationCode />
      ) : (
        <div className='main-header__wallet-modal-output-balance'>
            <div className='main-header__wallet-modal-output-balance-body'>
                <span className="main-header__wallet-modal-output-balance-body-close" onClick={handleCloseClick}>✖</span>
                <span className='main-header__wallet-modal-output-balance-body-text'>Вывод денежных средств на кошелёк</span>
                <span className='main-header__wallet-modal-output-balance-body-text-min'>Минимальная сумма для вывода - {data} БАЛЛОВ</span>
                <input
                    value={value}
                    onChange={onChange}
                    className='main-header__wallet-modal-output-balance-input'
                    type='text'
                    min={25}
                    placeholder='Введите количество баллов для вывода' />
                <button
                    className='main-header__wallet-modal-output-balance-button'
                    onClick={handleButtonClick}
                    disabled={isButtonDisabled}>Подтвердить
                </button>
            </div>
            <button
              onClick={openInstruction}
              style={{background: 'none', border: 'none', cursor: 'pointer', color: 'white', paddingLeft: '16px', paddingBottom: '16px'}}>
              Как вывести средства?
            </button>
        </div>
      )}
      {instruction && <InstructionOutBalance onClose={closeInstruction}/> }
    </div>

  )
}
