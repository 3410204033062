import React from 'react';
import "./instructionoutbalance.css";

export default function InstructionOutBalance({ onClose }) {


  const handleCloseClick = () => {
    onClose();
  }

  return (
    <div className='instructrion'>
      <div className='instructrion_wrapper'>
        <h2 className='instructrion_header'>Инструкция по выводу средств</h2>
        <span className="instructrion_header_close" onClick={handleCloseClick}>✖</span>
        <ol>
          <li className='instructrion_point'>Нажмите на кнопку "Вывести"</li>
          <img src='../../img/InstructionWallet.png' alt='Вывести' style={{width: '250px', height: '70px'}}></img>
          <li className='instructrion_point'>Введите в поле для ввода количество баллов, которое необходимо вывести</li>
          <img src='../../img/InstructionSumm.png' alt='Количество баллов' style={{width: '250px', height: '150px'}}></img>
          <li className='instructrion_point'>Нажмите кнопку "Подтвердить"</li>
          <li className='instructrion_point'>На почту придет код из 4х цифр (проверьте папку спам, если код не пришел)</li>
          <li className='instructrion_point'>Введите код, который пришел на почту, в открывшемся модальном окне</li>
          <img src='../../img/InstructionCode.png' alt='Код' style={{width: '250px', height: '150px'}}></img>
          <li className='instructrion_point'>Нажмите кнопку "Отправить"</li>
          <li className='instructrion_point'>Деньги поступят на кошелек, который Вы указали в личном кабинетепш</li>
        </ol>
      </div>
    </div>
  )
}
