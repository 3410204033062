import { useState, useEffect } from 'react';
import axios from 'axios';
import './style.css';

const URL_API_TOP3_USERS = 'https://partner-system.ru/api/general/top_users/';
const ACCESS_TOKEN_USER = localStorage.getItem('ACCESS_TOKEN');

export default function TopThreeCustomer() {
  const [data, setData] = useState<Array<any>>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(URL_API_TOP3_USERS, {
          headers: {
            'Authorization': `Bearer ${ACCESS_TOKEN_USER}`
          }
        });
        const res = response.data;
        setData(res);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [])


  return (
    <div className='top-customer partner-code'>
      <table className='partner-code__table'>
        <thead className='partner-code__table-head'>
          <tr className='partner-code__table-row'>
            <th scope='col' className='partner-code__table-cell-head-th'>ТОП 3 участников</th>
            <th scope='col' className='partner-code__table-cell-head-th'>Получено баллов за месяц</th>
            <th scope='col' className='partner-code__table-cell-head-th'>Всего получено баллов</th>
            <th scope='col' className='partner-code__table-cell-head-th'>Привлечено пользователей</th>
            <th scope='col' className='partner-code__table-cell-head-th'>Привлечено партнёров</th>
            <th scope='col' className='partner-code__table-cell-head-th'>Ведет активные группы
            </th>
          </tr>
        </thead>
        <tbody className='partner-code__body'>
          {data.map((item, index) => (
            <tr key={index} className='partner-code__table-row'>
              <td className='partner-code__table-cell'>
                <div className="ava-corrector">
                  <div className="ava-div">
                    <img className="ava"
                      style={{ borderRadius: '50%', marginRight: '8px' }}
                      width={15}
                      height={15}
                      src={item.avatar ? item.avatar : './img/avatar.svg'}
                      alt='ava' />
                  </div>
                  <span>{item.username}</span>
                </div>
              </td>
              <td className='partner-code__table-cell'>{item.points_per_month || '-'}</td>
              <td className='partner-code__table-cell'>{item.total_points || '-'}</td>
              <td className='partner-code__table-cell'>{item.attracted_users || '-'}</td>
              <td className='partner-code__table-cell'>{item.attracted_partners || '-'}</td>
              <td className='partner-code__table-cell'>{item.active_groups || '-'}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

