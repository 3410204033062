import React from 'react';
import '../partnerCode/partnercode.css';

export default function ProfileModalSuccess({ onClose }) {

  const handleOverlayClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  return (
    <div className='modal-overlay' onClick={handleOverlayClick}>
      <div className='modal-promocode-ok'>
        <div className='modal-promocode-ok__header' style={{textAlign: 'center'}}>
          <span className='modal-promocode-ok__header-text'>Запрос на перечисление средств отправлен!</span>
        </div>
        <img className='modal-promocode-ok__image' src='img/iconOk.svg' alt='ok' />
      </div>
    </div>
  )
}

