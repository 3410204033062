import React, { useRef, useState } from 'react';
import axios from 'axios';
import ModalSucess from "./ModalSucess";
import ModalErrorTimeCode from "./ModalErrorTimeCode";
import './modalverificationcode.css';


const URL_CONFIRM_VERIFY_CODE = 'https://partner-system.ru/api/sms/confirm_verify_code/';
const ACCESS_TOKEN_USER = localStorage.getItem('ACCESS_TOKEN');

export default function ModalVerificationCode() {
  const [showModalSuccess, setShowModalSuccess] = useState(false);
  const [showModalErrorCode, setShowModalErrorCode] = useState(false);
  const [showModalErrorTimeCode, setShowModalErrorTimeCode] = useState(false);
  const [showModalVerificationCode, setShowModalVerificationCode] = useState(true); //

  const inputRefs = useRef<Array<HTMLInputElement | null>>(Array(4).fill(null));

  const handleButtonClickModal = () => {
    setShowModalSuccess(true);
  }

  const handleButtonClickModalErrorCode = () => {
    setShowModalErrorCode(true);
  }

  const handleButtonClickModalTimeErrorCode = () => {
    setShowModalErrorTimeCode(true);
  }

  const postConfirmVerifyCode = () => {
      console.log('Before axios.post');
      axios
        .post(URL_CONFIRM_VERIFY_CODE,
          getEnteredValues(),
          {headers: {'Authorization': `Bearer ${ACCESS_TOKEN_USER}`}
      })
      .then((response) => {
        handleButtonClickModal();
        setShowModalVerificationCode(false);
      })
      .catch((e) => {
        if (e.message.includes('400')) {
          handleButtonClickModalErrorCode();
        } else if (e.message.includes('408')) {
          handleButtonClickModalTimeErrorCode();
          setShowModalVerificationCode(false);
        }
      })
  };


  const handleInput = (index: number, event: React.FormEvent<HTMLInputElement>) => {
    const inputValue = event.currentTarget.value;

    if (inputValue.length === 1 && index < inputRefs.current.length - 1) {
      const nextInput = inputRefs.current[index + 1];
      if (nextInput) {
        nextInput.focus();
      }
    }
  };

  const handleInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (event.key === 'Backspace' && event.currentTarget.value === '') {
      event.preventDefault();
      if (index > 0) {
        const prevInput = inputRefs.current[index - 1];
        if (prevInput) {
          prevInput.value = '';
          prevInput.focus();
        }
      }
    }
  };

  const getEnteredValues = () => {
    const enteredValues: string[] = [];
    for (let i = 0; i < inputRefs.current.length; i++) {
      const inputRef = inputRefs.current[i];
      if (inputRef) {
        enteredValues.push(inputRef.value);
      }
    }

    const resultCode = enteredValues.join('');

    const VerifyCodePatch = {
      'code': resultCode
    }
    return VerifyCodePatch;
  };


  return (
    <div className='modal-verification-code'>
      {showModalVerificationCode && (
      <div className='modal-verification-code__wrapper'>
          <span className='main-header__wallet-modal-output-balance-body-text' style={{textAlign: 'center'}}>
            Введите проверочный код отправленный на почту.<br/>
            <span className='main-header__wallet-modal-output-balance-body-text' style={{textAlign: 'center', fontSize: '12px'}}>
              Если код не пришел, проверьте папку спам!
            </span>
          </span>
          <div className='modal-verification-code__input'>
          {Array.from({ length: 4 }, (_, index) => (
              <input
                key={index}
                id='myInput'
                ref={(el) => (inputRefs.current[index] = el)}
                className='input__verification_code'
                type='text'
                maxLength={1}
                onInput={(e) => handleInput(index, e)}
                onKeyDown={(e) => handleInputKeyDown(e, index)}
              />
            ))}
          </div>
          {showModalErrorCode && <span style={{color: 'red', paddingTop: '8px', fontSize: '16px'}}>Введен неверный код</span>}
            <button className='main-header__wallet-modal-output-balance-button' onClick={postConfirmVerifyCode}>
              Отправить
            </button>
        </div>
        )}
        {showModalSuccess && <ModalSucess onClose={() => setShowModalSuccess(false)} />}
        {showModalErrorTimeCode && <ModalErrorTimeCode onClose={() => setShowModalErrorTimeCode(false)} />}
    </div>
  )
}
