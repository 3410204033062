import React from 'react';
import Select from 'react-select';


const options = [
    { value: 'partner', label: 'Партнёр' },
    { value: 'consultant', label: 'Незавиcимый консультант' },
    { value: 'referral', label: 'Реферал' }
  ]

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: 'white',
      backgroundColor: '#2B3243',
    }),
    menu: (provided, state) => ({
      ...provided,
      backgroundColor: '#2B3243',
      marginTop: '4px',
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: 'white',
    }),
    control: (baseStyles, state) => ({
      ...baseStyles,
      borderColor: 'none',
      border: '1px solid #8586A5',
      backgroundColor: '#2B3243',
      width: '300px',
      }),
  }



  export default function MyComponent({ fetchDataPartner, fetchDataConsultant, fetchDataReferal, selectedOption, setSelectedOption }) {

    const selectOptionsValue = (option) => {
      setSelectedOption(option.value);

      switch(option.value) {
        case "partner":
          fetchDataPartner();
          break;
        case "consultant":
          fetchDataConsultant();
          break;
        case "referral":
          fetchDataReferal();
          break;
      }
    };

    return (
      <Select
        onChange={selectOptionsValue}
        options={options}
        styles={customStyles}
        defaultValue={options[0]} />
    );
}