import MainPage from '../../pages/main-page/main-page';
import { BrowserRouter, Routes, Route}  from "react-router-dom";
import Profile from "../profile/profile";
import Page404 from "../../pages/page-404/page-404";
import AdminPage from "../admin/admin";
import Auth from '../authorization/Auth';

export default function App(): JSX.Element {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/'>
          <Route index element={<Auth />}></Route>
          <Route path="/main" element={<MainPage />}></Route>
          <Route path="/profile" element={<Profile />}></Route>
          <Route path="/admin" element={<AdminPage />}></Route>
          <Route path="*" element={<Page404 />}></Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}


