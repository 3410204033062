import React, {Fragment} from 'react';
import AdminHeader from "../admin-header/admin-header";
import AdminTabs from './AdminTabs';

export default function AdminPage() {


  return (
    <Fragment>
      <AdminHeader />
      <AdminTabs />
    </Fragment>
  );
}
