import { useState, useEffect } from 'react';
import axios from 'axios';
import Tooltip from '../../components/tooltip/tooltip';
import telegram from './telegram-icon.svg';
import whatsapp from './whatsapp-icon.svg';
import './main-clients.css';

const CLIENTS_AND_SUBPARTNERS_API = 'https://partner-system.ru/api/partner/clients_and_subpartners/';
const ACCESS_TOKEN_USER = localStorage.getItem('ACCESS_TOKEN');

export default function MainClients() {
  const [directorData, setDirectorData] = useState<Array<any>>([]);
  const [partnerData, setPartnerData] = useState<Array<any>>([]);
  const [consultantData, setConsultantData] = useState<Array<any>>([]);
  const [referaltData, setReferalData] = useState<Array<any>>([]);


  useEffect(() => {
    const fetchData = () => {
      axios
        .get(CLIENTS_AND_SUBPARTNERS_API, {
          headers: {
            'Authorization': `Bearer ${ACCESS_TOKEN_USER}`
          }
        })
        .then((response) => {
          let dir = response.data.map(item => item.directors);
          setDirectorData(dir[0]);
          let par = response.data.map(item => item.partners);
          setPartnerData(par[0]);
          let consult = response.data.map(item => item.consultants);
          setConsultantData(consult[0])
          let ref = response.data.map(item => item.referals);
          setReferalData(ref[0])
        })
        .catch((e) => {
          console.error(e);
        })
    }

    fetchData();
  }, []);

  return (
    <div className='main-table-wrapper'>
      <div className='main-clients partner-code'>
        <div className='user-levels'>
          <ul className='user-levels__list'>
            <li className='user-levels__item'>
              <div className="user-levels__block partner-code__user-level"></div>
              <h3>Директор</h3>
            </li>
            <li className='user-levels__item'>
              <div className="user-levels__block user-levels__block--partner partner-code__user-level"></div>
              <h3>Партнер</h3>
            </li>
            <li className='user-levels__item'>
              <div className="user-levels__block user-levels__block--consultant partner-code__user-level"></div>
              <h3>Консультант</h3>
            </li>
            <li className='user-levels__item'>
              <div className="user-levels__block user-levels__block--referal partner-code__user-level"></div>
              <h3>Реферал</h3>
            </li>
          </ul>
        </div>
        <table className='partner-code__table'>
          <thead className='partner-code__table-head'>
            <tr className='partner-code__table-row'>
              <th scope='col' className='partner-code__table-cell-head-th'>Никнейм</th>
              <th scope='col' className='partner-code__table-cell-head-th'>Активирован промокод</th>
              <th scope='col' className='partner-code__table-cell-head-th'>Баллов за месяц</th>
              <th scope='col' className='partner-code__table-cell-head-th'>Всего получено баллов
              </th>
              <th scope='col' className='partner-code__table-cell-head-th'>Получено от партнеров
              </th>
              <th scope='col' className='partner-code__table-cell-head-th'>Привлечено пользователей
              </th>
              <th scope='col' className='partner-code__table-cell-head-th'>Привлечено партнеров
              </th>
            </tr>
          </thead>
          <tbody className='partner-code__body'>

      {directorData.map((item, index) => (
        <tr key={index} className='partner-code__table-row'>
          <td className='partner-code__table-cell'>
            <div className="partner-code__user-level"></div>
            <div className="top-user__name">
            <img style={{ borderRadius: '50%' }}
                width={25} height={25}
                src={item.avatar ? item.avatar : './img/avatar.svg'}
                alt='ava' />
              <p>{item.username || '-'}</p>
            </div>
            <div className='partner-code__socials'>
              <Tooltip text={item.username || '-'} placement='top'>
                <img src={telegram} width="12" height="12" alt='telegram icon' />
              </Tooltip>

              <Tooltip text={item.username || '-'} placement='top'>
                <img src={whatsapp} width="12" height="12" alt="whatsapp icon" />
              </Tooltip>
            </div>
          </td>
          <td className='partner-code__table-cell'>{item.activated_promocode || '-'}</td>
          <td className='partner-code__table-cell'>{item.points_per_month || '-'}</td>
          <td className='partner-code__table-cell'>{item.total_points || '-'}</td>
          <td className='partner-code__table-cell'>{item.points_from_partners || '-'}</td>
          <td className='partner-code__table-cell'>{item.attracted_users || '-'}</td>
          <td className='partner-code__table-cell'>{item.attracted_partners || '-'}</td>
        </tr>
      ))}
      {directorData.map((item, index) => item.partners.map(item =>
        <tr key={index} className='partner-code__table-row'>
          <td className='partner-code__table-cell'>
            <div className="partner-code__user-level user-levels__block--partner"></div>
            <div className="top-user__name">
            <img style={{ borderRadius: '50%' }}
                width={25} height={25}
                src={item.avatar ? item.avatar : './img/avatar.svg'}
                alt='ava' />
              <p>{item.username || '-'}</p>
            </div>
            <div className='partner-code__socials'>
              <Tooltip text={item.username || '-'} placement='top'>
                <img src={telegram} width="12" height="12" alt='telegram icon' />
              </Tooltip>

              <Tooltip text={item.username || '-'} placement='top'>
                <img src={whatsapp} width="12" height="12" alt="whatsapp icon" />
              </Tooltip>
            </div>
          </td>
          <td className='partner-code__table-cell'>{item.activated_promocode || '-'}</td>
          <td className='partner-code__table-cell'>{item.points_per_month || '-'}</td>
          <td className='partner-code__table-cell'>{item.total_points || '-'}</td>
          <td className='partner-code__table-cell'>{item.points_from_partners || '-'}</td>
          <td className='partner-code__table-cell'>{item.attracted_users || '-'}</td>
          <td className='partner-code__table-cell'>{item.attracted_partners || '-'}</td>
        </tr>
      ))}
      {directorData.map((item, index) => item.consultants.map(item =>
        <tr key={index} className='partner-code__table-row'>
          <td className='partner-code__table-cell'>
            <div className="partner-code__user-level user-levels__block--partner"></div>
            <div className="top-user__name">
            <img style={{ borderRadius: '50%' }}
                width={25} height={25}
                src={item.avatar ? item.avatar : './img/avatar.svg'}
                alt='ava' />
              <p>{item.username || '-'}</p>
            </div>
            <div className='partner-code__socials'>
              <Tooltip text={item.username || '-'} placement='top'>
                <img src={telegram} width="12" height="12" alt='telegram icon' />
              </Tooltip>

              <Tooltip text={item.username || '-'} placement='top'>
                <img src={whatsapp} width="12" height="12" alt="whatsapp icon" />
              </Tooltip>
            </div>
          </td>
          <td className='partner-code__table-cell'>{item.activated_promocode || '-'}</td>
          <td className='partner-code__table-cell'>{item.points_per_month || '-'}</td>
          <td className='partner-code__table-cell'>{item.total_points || '-'}</td>
          <td className='partner-code__table-cell'>{item.points_from_partners || '-'}</td>
          <td className='partner-code__table-cell'>{item.attracted_users || '-'}</td>
          <td className='partner-code__table-cell'>{item.attracted_partners || '-'}</td>
        </tr>
      ))}
      {directorData.map((item, index) => item.referals.map(item =>
        <tr key={index} className='partner-code__table-row'>
          <td className='partner-code__table-cell'>
            <div className="partner-code__user-level user-levels__block--partner"></div>
            <div className="top-user__name">
            <img style={{ borderRadius: '50%' }}
                width={25} height={25}
                src={item.avatar ? item.avatar : './img/avatar.svg'}
                alt='ava' />
              <p>{item.username || '-'}</p>
            </div>
            <div className='partner-code__socials'>
              <Tooltip text={item.username || '-'} placement='top'>
                <img src={telegram} width="12" height="12" alt='telegram icon' />
              </Tooltip>

              <Tooltip text={item.username || '-'} placement='top'>
                <img src={whatsapp} width="12" height="12" alt="whatsapp icon" />
              </Tooltip>
            </div>
          </td>
          <td className='partner-code__table-cell'>{item.activated_promocode || '-'}</td>
          <td className='partner-code__table-cell'>{item.points_per_month || '-'}</td>
          <td className='partner-code__table-cell'>{item.total_points || '-'}</td>
          <td className='partner-code__table-cell'>{item.points_from_partners || '-'}</td>
          <td className='partner-code__table-cell'>{item.attracted_users || '-'}</td>
          <td className='partner-code__table-cell'>{item.attracted_partners || '-'}</td>
        </tr>
      ))}



      {partnerData.map((item, index) => (
        <tr key={index} className='partner-code__table-row'>
          <td className='partner-code__table-cell'>
            <div className="partner-code__user-level"></div>
            <div className="top-user__name">
            <img style={{ borderRadius: '50%' }}
                width={25} height={25}
                src={item.avatar ? item.avatar : './img/avatar.svg'}
                alt='ava' />
              <p>{item.username || '-'}</p>
            </div>
            <div className='partner-code__socials'>
              <Tooltip text={item.username || '-'} placement='top'>
                <img src={telegram} width="12" height="12" alt='telegram icon' />
              </Tooltip>

              <Tooltip text={item.username || '-'} placement='top'>
                <img src={whatsapp} width="12" height="12" alt="whatsapp icon" />
              </Tooltip>
            </div>
          </td>
          <td className='partner-code__table-cell'>{item.activated_promocode || '-'}</td>
          <td className='partner-code__table-cell'>{item.points_per_month || '-'}</td>
          <td className='partner-code__table-cell'>{item.total_points || '-'}</td>
          <td className='partner-code__table-cell'>{item.points_from_partners || '-'}</td>
          <td className='partner-code__table-cell'>{item.attracted_users || '-'}</td>
          <td className='partner-code__table-cell'>{item.attracted_partners || '-'}</td>
        </tr>
      ))}
      {partnerData.map((item, index) => item.consultants.map(item =>
        <tr key={index} className='partner-code__table-row'>
          <td className='partner-code__table-cell'>
            <div className="partner-code__user-level user-levels__block--partner"></div>
            <div className="top-user__name">
            <img style={{ borderRadius: '50%' }}
                width={25} height={25}
                src={item.avatar ? item.avatar : './img/avatar.svg'}
                alt='ava' />
              <p>{item.username || '-'}</p>
            </div>
            <div className='partner-code__socials'>
              <Tooltip text={item.username || '-'} placement='top'>
                <img src={telegram} width="12" height="12" alt='telegram icon' />
              </Tooltip>

              <Tooltip text={item.username || '-'} placement='top'>
                <img src={whatsapp} width="12" height="12" alt="whatsapp icon" />
              </Tooltip>
            </div>
          </td>
          <td className='partner-code__table-cell'>{item.activated_promocode || '-'}</td>
          <td className='partner-code__table-cell'>{item.points_per_month || '-'}</td>
          <td className='partner-code__table-cell'>{item.total_points || '-'}</td>
          <td className='partner-code__table-cell'>{item.points_from_partners || '-'}</td>
          <td className='partner-code__table-cell'>{item.attracted_users || '-'}</td>
          <td className='partner-code__table-cell'>{item.attracted_partners || '-'}</td>
        </tr>
      ))}
      {partnerData.map((item, index) => item.referals.map(item =>
        <tr key={index} className='partner-code__table-row'>
          <td className='partner-code__table-cell'>
          <div className="partner-code__user-level user-levels__block--partner"></div>
            <div className="top-user__name">
            <img style={{ borderRadius: '50%' }}
                width={25} height={25}
                src={item.avatar ? item.avatar : './img/avatar.svg'}
                alt='ava' />
              <p>{item.username || '-'}</p>
            </div>
            <div className='partner-code__socials'>
              <Tooltip text={item.username || '-'} placement='top'>
                <img src={telegram} width="12" height="12" alt='telegram icon' />
              </Tooltip>

              <Tooltip text={item.username || '-'} placement='top'>
                <img src={whatsapp} width="12" height="12" alt="whatsapp icon" />
              </Tooltip>
            </div>
            {item.username}
          </td>
          <td className='partner-code__table-cell'>{item.activated_promocode || '-'}</td>
          <td className='partner-code__table-cell'>{item.points_per_month || '-'}</td>
          <td className='partner-code__table-cell'>{item.total_points || '-'}</td>
          <td className='partner-code__table-cell'>{item.points_from_partners || '-'}</td>
          <td className='partner-code__table-cell'>{item.attracted_users || '-'}</td>
          <td className='partner-code__table-cell'>{item.attracted_partners || '-'}</td>
        </tr>
      ))}



        {consultantData.map((item, index) => (
        <tr key={index} className='partner-code__table-row'>
          <td className='partner-code__table-cell'>
            <div className="partner-code__user-level user-levels__block--consultant"></div>
              <div className="top-user__name">
                <img style={{ borderRadius: '50%' }}
                  width={25} height={25}
                  src={item.avatar ? item.avatar : './img/avatar.svg'}
                  alt='ava'
                />
                <p>{item.username || '-'}</p>
              </div>
              <div className='partner-code__socials'>
                <Tooltip text={item.username || '-'} placement='top'>
                  <img src={telegram} width="12" height="12" alt='telegram icon' />
                </Tooltip>

                <Tooltip text={item.username || '-'} placement='top'>
                  <img src={whatsapp} width="12" height="12" alt="whatsapp icon" />
                </Tooltip>
              </div>
          </td>
            <td className='partner-code__table-cell'>{item.activated_promocode || '-'}</td>
            <td className='partner-code__table-cell'>{item.points_per_month || '-'}</td>
            <td className='partner-code__table-cell'>{item.total_points || '-'}</td>
            <td className='partner-code__table-cell'>{item.points_from_partners || '-'}</td>
            <td className='partner-code__table-cell'>{item.attracted_users || '-'}</td>
            <td className='partner-code__table-cell'>{item.attracted_partners || '-'}</td>
        </tr>
      ))}
      {consultantData.map((item, index) => item.referals.map(item =>
        <tr key={index} className='partner-code__table-row'>
          <td className='partner-code__table-cell'>
            <div className="partner-code__user-level user-levels__block--referal"></div>
            <div className="top-user__name">
              <img style={{ borderRadius: '50%' }}
                width={25} height={25}
                src={item.avatar ? item.avatar : './img/avatar.svg'}
                alt='ava'
              />
              <p>{item.username || '-'}</p>
            </div>
            <div className='partner-code__socials'>
              <Tooltip text={item.username || '-'} placement='top'>
                <img src={telegram} width="12" height="12" alt='telegram icon' />
              </Tooltip>

              <Tooltip text={item.username || '-'} placement='top'>
                <img src={whatsapp} width="12" height="12" alt="whatsapp icon" />
              </Tooltip>
            </div>
          </td>
          <td className='partner-code__table-cell'>{item.activated_promocode || '-'}</td>
          <td className='partner-code__table-cell'>{item.points_per_month || '-'}</td>
          <td className='partner-code__table-cell'>{item.total_points || '-'}</td>
          <td className='partner-code__table-cell'>{item.points_from_partners || '-'}</td>
          <td className='partner-code__table-cell'>{item.attracted_users || '-'}</td>
          <td className='partner-code__table-cell'>{item.attracted_partners || '-'}</td>
        </tr>
      ))}

        {referaltData.map((item, index) => (
        <tr key={index} className='partner-code__table-row'>
          <td className='partner-code__table-cell'>
            <div className="partner-code__user-level user-levels__block--referal"></div>
              <div className="top-user__name">
                <img style={{ borderRadius: '50%' }}
                  width={25} height={25}
                  src={item.avatar ? item.avatar : './img/avatar.svg'}
                  alt='ava'
                />
                <p>{item.username || '-'}</p>
              </div>
              <div className='partner-code__socials'>
                <Tooltip text={item.username || '-'} placement='top'>
                  <img src={telegram} width="12" height="12" alt='telegram icon' />
                </Tooltip>

                <Tooltip text={item.username || '-'} placement='top'>
                  <img src={whatsapp} width="12" height="12" alt="whatsapp icon" />
                </Tooltip>
              </div>
          </td>
            <td className='partner-code__table-cell'>{item.activated_promocode || '-'}</td>
            <td className='partner-code__table-cell'>{item.points_per_month || '-'}</td>
            <td className='partner-code__table-cell'>{item.total_points || '-'}</td>
            <td className='partner-code__table-cell'>{item.points_from_partners || '-'}</td>
            <td className='partner-code__table-cell'>{item.attracted_users || '-'}</td>
            <td className='partner-code__table-cell'>{item.attracted_partners || '-'}</td>
        </tr>
        ))}

          </tbody>
        </table>
      </div>
    </div>
  );
}
