import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';

const URL_API_TOP_MY_LEVEL = 'https://partner-system.ru/api/general/top_my_level_users/';
const ACCESS_TOKEN_USER = localStorage.getItem('ACCESS_TOKEN');

const columns = [
  { name: 'avatar', header: '', defaultVisible: true, minWidth: 50, maxWidth: 50, render: (params) => <img src={params.value ? params.value : './img/avatar.svg'} alt='avatar' style={{width: '30px', height: '30px', borderRadius: '50%'}}/> },
  { name: 'username', header: 'никнейм', defaultFlex: 1, minWidth: 120, maxWidth: 120},
  { name: 'points_per_month', header: 'Баллов за месяц', defaultFlex: 1, minWidth: 250, maxWidth: 250, type: 'number' },
  { name: 'total_points', header: 'Всего баллов', defaultFlex: 1, minWidth: 200, maxWidth: 200, type: 'number' },
  { name: 'attracted_users', header: 'Привлечено пользователей', minWidth: 250, maxWidth: 250, type: 'number' },
  { name: 'attracted_partners', header: 'Привлечено парнеров', minWidth: 250, maxWidth: 250, type: 'number' },
  { name: 'active_groups', header: 'Активные группы', minWidth: 250, maxWidth: 250, type: 'number' },
];

const gridStyle = {
  minHeight: 600,
  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.25), 0px -4px 10px rgba(0, 0, 0, 0.25)',
  marginTop: '20px',
  color: 'white',
  height: '300px',
  backgroundColor: '#2B3243',
  borderRadius: '8px',
  textAlign: 'center',
 }

export default function MainTopN() {
  const [data, setData] = useState<Array<any>>([]);

  useEffect(() => {
    fetchData();
  }, [])

  const fetchData = async () => {
    try {
      const response = await axios.get(URL_API_TOP_MY_LEVEL, {
        headers: {
          'Authorization': `Bearer ${ACCESS_TOKEN_USER}`
        }
      });
      const res = response.data['users'];
      setData(res);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <ReactDataGrid
        idProperty="id"
        columns={columns}
        dataSource={data}
        style={gridStyle}
      />
    </div>
  )
}
