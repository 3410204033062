import React from "react";
import {Link, useNavigate} from "react-router-dom";
import "./admin-style.css";


export default function AdminHeader() {
  const navigate = useNavigate();

  return (
    <header className="main-header">
      <div className="main-logo">
        <Link to="/">
          <img src="img/main-logo.svg" />
        </Link>
      </div>
      <h1 className="main-header__title">
        Панель администратора
      </h1>
      <div className="height-corrector"></div>
      <div className="admin-header__exit-button--wrapper">
        <Link to="/">
          <button id='exit-btn' className="admin-header__exit-button">Выйти</button>
        </Link>
      </div>
    </header>
  );
}
