import React, { useState, useEffect } from 'react';
import './walletHeader.css';
import ModalOutputBalance from "../modalOutputBalance/ModalOutputBalance";
import axios from 'axios';

const URL_API_BALANCE = 'https://partner-system.ru/api/general/balance/';
const ACCESS_TOKEN_USER = localStorage.getItem('ACCESS_TOKEN');

export default function WalletHeader() {
  const [showModalOutputBalance, setShowModalOutputBalance] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState('');
  const [data, setData] = useState<Array<any>>([]);

  useEffect(() => {
    const savedCurrency = localStorage.getItem('selectedCurrency');
    if (savedCurrency) {
      setSelectedCurrency(savedCurrency);
    }
  }, [])

  useEffect(() => {
    const fetchData = () => {
      axios
        .get(URL_API_BALANCE, {
          headers: {
            'Authorization': `Bearer ${ACCESS_TOKEN_USER}`
          }
        })
        .then((response) => {
          setData(response.data);
        })
        .catch((e) => {
          console.error(e);
        })
    }

    fetchData();
  }, []);

  const handleButtonClick = () => {
    setShowModalOutputBalance(true);
    document.body.classList.add('modal-open');
  }

  const handleSelectChange = (event) => {
    const currency = event.target.value;
    setSelectedCurrency(currency);
    localStorage.setItem('selectedCurrency', currency);
  }

  const convertBalance = (balance, currency) => {
    const conversionRates = {
        BAL: 1,
        EUR: data.map(item => item.currencies['EUR']),
        USD: data.map(item => item.currencies['USD']),
        RUB: data.map(item => item.currencies['RUB']),
        BYN: data.map(item => item.currencies['BYN']),
        KZT: data.map(item => item.currencies['KZT']),
        KGS: data.map(item => item.currencies['KGS']),
        GEL: data.map(item => item.currencies['GEL']),
        UZS: data.map(item => item.currencies['UZS']),
        UAH: data.map(item => item.currencies['UAH']),
        MDL: data.map(item => item.currencies['MDL']),
    }

    if (currency in conversionRates) {
      const conversionRate = conversionRates[currency];
      const convertedBalance = balance * conversionRate;
      return convertedBalance;
    }

    return "Error";
  }

  const balanceInCryptoTokens = data.map(item => item.balance);

  const convertedBalance = convertBalance(balanceInCryptoTokens, selectedCurrency);
  const points_earned = data.map(item => item.total_points_earned);

  return (
      <div className="main-header__wallet">
            <div className="main-header__wallet-balance">
                <div className='main-header__wallet-balance-info'>
                    <span className='main-header__wallet-balance-money'>{convertedBalance}</span>
                    <div className="select-border">
                        <select
                        className="main-header__wallet-balance-select"
                        value={selectedCurrency}
                        onChange={handleSelectChange}>
                          <option value='BAL'>БАЛЛЫ</option>
                          <option value='EUR'>EUR</option>
                          <option value='USD'>USD</option>
                          <option value='RUB'>RUB</option>
                          <option value='BYN'>BYN</option>
                          <option value='KZT'>KZT</option>
                          <option value='KGS'>KGS</option>
                          <option value='GEL'>GEL</option>
                          <option value='UZS'>UZS</option>
                          <option value='UAH'>UAH</option>
                          <option value='MDL'>MDL</option>
                        </select>
                    </div>
                </div>
                <div className='main-header__wallet-balance-all'>
                    <span className='main-header__wallet-balance-all-info'>{points_earned}</span>
                    <span className='main-header__wallet-balance-all-info-text'>за всё время</span>
                </div>
            </div>
            <button className="main-header__wallet-button-output-balance" onClick={handleButtonClick}>Вывести</button>
            {showModalOutputBalance &&
            <ModalOutputBalance
            onClose={()=> setShowModalOutputBalance(false)} />}
      </div>
  )
}
