import { useEffect, useState } from 'react';
import axios from 'axios';
import './user-cash-config.css';
import MyComponent from './select';
import { FormData } from '../../types/admin-types/admin-config-types';


const initialFormData: FormData = {
  id: 1,
  self_expenses_base_cashback: 0,
  level_1_self_expenses_amount: 0,
  level_1_self_expenses_percent: 0,
  level_2_self_expenses_amount: 0,
  level_2_self_expenses_percent: 0,
  level_3_self_expenses_amount: 0,
  level_3_self_expenses_percent: 0,
  level_1_referals_expenses_amount: 0,
  level_1_referals_expenses_percent: 0,
  level_2_referals_expenses_amount: 0,
  level_2_referals_expenses_percent: 0,
  level_3_referals_expenses_amount: 0,
  level_3_referals_expenses_percent: 0,
  level_1_referals_count: 0,
  level_1_referals_count_percent: 0,
  level_2_referals_count: 0,
  level_2_referals_count_percent: 0,
  level_3_referals_count: 0,
  level_3_referals_count_percent: 0,
  level_1_consultants_count: 0,
  level_1_consultants_count_percent: 0,
  level_2_consultants_count: 0,
  level_2_consultants_count_percent: 0,
  level_3_consultants_count: 0,
  level_3_consultants_count_percent: 0,
  level_1_consultants_expenses_amount: 0,
  level_1_consultants_expenses_percent: 0,
  level_2_consultants_expenses_amount: 0,
  level_2_consultants_expenses_percent: 0,
  level_3_consultants_expenses_amount: 0,
  level_3_consultants_expenses_percent: 0,
  self_expenses_amount_to_become_partner: 0,
  referals_expenses_amount_to_become_partner: 0,
  consultants_expenses_amount_to_become_partner: 0,
  count_of_months_of_compilance_to_become_partner: 0,
  count_of_months_of_non_compilance_to_downgrade: 0,
}

const URL_API_ADMIN_CONFIG_REFERAL = 'https://partner-system.ru/api/admin/config/referal/';
const URL_API_ADMIN_CONFIG_CONSULTANT = 'https://partner-system.ru/api/admin/config/consultant/';
const URL_API_ADMIN_CONFIG_PARTNER = 'https://partner-system.ru/api/admin/config/partner/';
const ACCESS_TOKEN_USER = localStorage.getItem('ACCESS_TOKEN');

export default function UserCashConfig() {
  const [data, setData] = useState<FormData>(initialFormData);
  const [selectedOption, setSelectedOption] = useState('');

  useEffect(() => {
    fetchDataConsultant();
  }, [])

  useEffect(() => {
    fetchDataPartner();
  }, [])



  const fetchDataPartner = () => {
      axios
        .get(URL_API_ADMIN_CONFIG_PARTNER, {
          headers: {
            'Authorization': `Bearer ${ACCESS_TOKEN_USER}`
          }
      })
      .then((response) => {
        const result = response.data[0];
        setData(result);
      })
      .catch((e) => {
        console.error(e);
      })
  };

  const fetchDataConsultant = () => {
    axios
      .get(URL_API_ADMIN_CONFIG_CONSULTANT, {
        headers: {
          'Authorization': `Bearer ${ACCESS_TOKEN_USER}`
        }
    })
    .then((response) => {
      setData(response.data[0]);
    })
    .catch((e) => {
      console.error(e);
    })
};

  const fetchDataReferal = () => {
    axios
      .get(URL_API_ADMIN_CONFIG_REFERAL, {
        headers: {
          'Authorization': `Bearer ${ACCESS_TOKEN_USER}`
        }
    })
    .then((response) => {
      setData(response.data[0]);
    })
    .catch((e) => {
      console.error(e);
    })
  };

const handleInputChange = (e) => {
  const {id, value} = e.target;
  setData((prevData) => ({
    ...prevData,
    [id]: value,
  }))
}

const handleSubmit = (e) => {
  e.preventDefault();

  const patchData = {
    ...data,
  };

  let apiUrl = '';
  if(selectedOption === 'partner') {
    const id_data = data.id;
    apiUrl = `https://partner-system.ru/api/admin/config/partner/${id_data}/`;
  } else if(selectedOption === 'consultant') {
    const id_data = data.id;
    apiUrl = `https://partner-system.ru/api/admin/config/consultant/${id_data}/`;
  } else if(selectedOption === 'referral') {
    const id_data = data.id;
    apiUrl = `https://partner-system.ru/api/admin/config/referal/${id_data}/`;

  }

  axios
    .patch(apiUrl, patchData, {
      headers: {
        Authorization: `Bearer ${ACCESS_TOKEN_USER}`,
      },
    })
    .then((response) => {
      console.log('Данные успешно отправлены');
    })
    .catch((error) => {
      console.error(error);
    })
}

  return (
    <section className="user-cash-config">
      <form className="user-cash-form" onSubmit={handleSubmit}>
        <div className="user-cash-form__header">
          <MyComponent
            fetchDataPartner={fetchDataPartner}
            fetchDataConsultant={fetchDataConsultant}
            fetchDataReferal={fetchDataReferal}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
          />
          <label htmlFor="cash" className='cash-label-gap'>
            <span style={{lineHeight: '60px'}}>Кэшбэк со своих продаж</span>
            <input
              id="self_expenses_base_cashback"
              className='user-cash__body-input'
              type="text"
              onChange={handleInputChange}
              value={data.self_expenses_base_cashback}
            />
            <span style={{lineHeight: '60px'}}>%</span>
          </label>
          <label htmlFor="limiter" className='cash-label-gap'>
            <span style={{lineHeight: '60px'}}>Ограничитель вывода средств</span>
            <input id="limiter" className='user-cash__body-input' type="text" defaultValue={0} />
            <span style={{lineHeight: '60px'}}>баллов</span>
          </label>
        </div>
        <hr style={{width: '95%', border: '1px solid #B63940', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.25)'}}></hr>
        <h1 style={{textAlign: 'center'}}>Настройки диверсифицированного кэшбэка</h1>
        <div className="user-cash-form__body">
          <div className='user-cash__body-elem'>
            <span>Собственные продажи(руб)</span>
            <div className='user-cash__body-preference'>
              <div className='user-cash__body-summ'>
                <span>Сумма</span>
                <input
                  id="level_1_self_expenses_amount"
                  className='user-cash__body-input'
                  type="text"
                  autoComplete="off"
                  disabled={selectedOption === 'referral'}
                  onChange={handleInputChange}
                  value={data.level_1_self_expenses_amount}
                />
                <input
                  id="level_2_self_expenses_amount"
                  className='user-cash__body-input'
                  type="text"
                  autoComplete="off"
                  disabled={selectedOption === 'referral'}
                  onChange={handleInputChange}
                  value={data.level_2_self_expenses_amount}
                />
                <input
                  id="level_3_self_expenses_amount"
                  className='user-cash__body-input'
                  type="text"
                  autoComplete="off"
                  disabled={selectedOption === 'referral'}
                  onChange={handleInputChange}
                  value={data.level_3_self_expenses_amount}
                />
              </div>
              <div className='user-cash__body-percent'>
                <span>Процент</span>
                <input
                  id="level_1_self_expenses_percent"
                  className='user-cash__body-input'
                  type="text"
                  autoComplete="off"
                  disabled={selectedOption === 'referral'}
                  onChange={handleInputChange}
                  value={data.level_1_self_expenses_percent}
                />
                <input
                  id="level_2_self_expenses_percent"
                  className='user-cash__body-input'
                  type="text"
                  autoComplete="off"
                  disabled={selectedOption === 'referral'}
                  onChange={handleInputChange}
                  value={data.level_2_self_expenses_percent}
                />
                <input
                  id="level_3_self_expenses_percent"
                  className='user-cash__body-input'
                  type="text"
                  autoComplete="off"
                  disabled={selectedOption === 'referral'}
                  onChange={handleInputChange}
                  value={data.level_3_self_expenses_percent}
                />
              </div>
            </div>
          </div>
          <div className='user-cash__body-elem'>
            <span>Продажи рефералов(руб)</span>
            <div className='user-cash__body-preference'>
              <div className='user-cash__body-summ'>
                <span>Сумма</span>
                <input
                  id="level_1_referals_expenses_amount"
                  className='user-cash__body-input'
                  type="text"
                  autoComplete="off"
                  disabled={selectedOption === 'referral'}
                  onChange={handleInputChange}
                  value={data.level_1_referals_expenses_amount}
                />
                <input
                  id="level_2_referals_expenses_amount"
                  className='user-cash__body-input'
                  type="text"
                  autoComplete="off"
                  disabled={selectedOption === 'referral'}
                  onChange={handleInputChange}
                  value={data.level_2_referals_expenses_amount}
                />
                <input
                  id="level_3_referals_expenses_amount"
                  className='user-cash__body-input'
                  type="text"
                  autoComplete="off"
                  disabled={selectedOption === 'referral'}
                  onChange={handleInputChange}
                  value={data.level_3_referals_expenses_amount}

                />
              </div>
              <div className='user-cash__body-percent'>
                <span>Процент</span>
                <input
                  id="level_1_referals_expenses_percent"
                  className='user-cash__body-input'
                  type="text"
                  autoComplete="off"
                  disabled={selectedOption === 'referral'}
                  onChange={handleInputChange}
                  value={data.level_1_referals_expenses_percent}
                />
                <input
                  id="level_2_referals_expenses_percent"
                  className='user-cash__body-input'
                  type="text"
                  autoComplete="off"
                  disabled={selectedOption === 'referral'}
                  onChange={handleInputChange}
                  value={data.level_2_referals_expenses_percent}
                />
                <input
                  id="level_3_referals_expenses_percent"
                  className='user-cash__body-input'
                  type="text"
                  autoComplete="off"
                  disabled={selectedOption === 'referral'}
                  onChange={handleInputChange}
                  value={data.level_3_referals_expenses_percent}

                />
              </div>
            </div>

          </div>
          <div className='user-cash__body-elem'>
            <span>Общий приток рефералов в месяц</span>
            <div className='user-cash__body-preference'>
              <div className='user-cash__body-summ'>
                <span>Количество</span>
                <input
                  id="level_1_referals_count"
                  className='user-cash__body-input'
                  type="text"
                  autoComplete="off"
                  disabled={selectedOption === 'referral'}
                  onChange={handleInputChange}
                  value={data.level_1_referals_count}

                />
                <input
                  id="level_2_referals_count"
                  className='user-cash__body-input'
                  type="text"
                  autoComplete="off"
                  disabled={selectedOption === 'referral'}
                  onChange={handleInputChange}
                  value={data.level_2_referals_count}

                />
                <input
                  id="level_3_referals_count"
                  className='user-cash__body-input'
                  type="text"
                  autoComplete="off"
                  disabled={selectedOption === 'referral'}
                  onChange={handleInputChange}
                  value={data.level_3_referals_count}

                />
              </div>
              <div className='user-cash__body-percent'>
                <span>Процент</span>
                <input
                  id="level_1_referals_count_percent"
                  className='user-cash__body-input'
                  type="text"
                  autoComplete="off"
                  disabled={selectedOption === 'referral'}
                  onChange={handleInputChange}
                  value={data.level_1_referals_count_percent}

                />
                <input
                  id="level_2_referals_count_percent"
                  className='user-cash__body-input'
                  type="text"
                  autoComplete="off"
                  disabled={selectedOption === 'referral'}
                  onChange={handleInputChange}
                  value={data.level_2_referals_count_percent}

                />
                <input
                  id="level_3_referals_count_percent"
                  className='user-cash__body-input'
                  type="text"
                  autoComplete="off"
                  disabled={selectedOption === 'referral'}
                  onChange={handleInputChange}
                  value={data.level_3_referals_count_percent}

                />
              </div>
            </div>

          </div>
          <div className='user-cash__body-elem'>
            <span>Общий приток консультантов в месяц</span>
            <div className='user-cash__body-preference'>
              <div className='user-cash__body-summ'>
                <span>Количество</span>
                <input
                  id="level_1_consultants_count"
                  className='user-cash__body-input'
                  type="text"
                  autoComplete="off"
                  disabled={selectedOption !== 'partner'}
                  onChange={handleInputChange}
                  value={data.level_1_consultants_count}

                />
                <input
                  id="level_2_consultants_count"
                  className='user-cash__body-input'
                  type="text"
                  autoComplete="off"
                  disabled={selectedOption !== 'partner'}
                  onChange={handleInputChange}
                  value={data.level_2_consultants_count}

                />
                <input
                  id="level_3_consultants_count"
                  className='user-cash__body-input'
                  type="text"
                  autoComplete="off"
                  disabled={selectedOption !== 'partner'}
                  onChange={handleInputChange}
                  value={data.level_3_consultants_count}

                />
              </div>
              <div className='user-cash__body-percent'>
                <span>Процент</span>
                <input
                  id="level_1_consultants_count_percent"
                  className='user-cash__body-input'
                  type="text"
                  autoComplete="off"
                  disabled={selectedOption !== 'partner'}
                  onChange={handleInputChange}
                  value={data.level_1_consultants_count_percent}

                />
                <input
                  id="level_2_consultants_count_percent"
                  className='user-cash__body-input'
                  type="text"
                  autoComplete="off"
                  disabled={selectedOption !== 'partner'}
                  onChange={handleInputChange}
                  value={data.level_2_consultants_count_percent}
                />
                <input
                  id="level_3_consultants_count_percent"
                  className='user-cash__body-input'
                  type="text"
                  autoComplete="off"
                  disabled={selectedOption !== 'partner'}
                  onChange={handleInputChange}
                  value={data.level_3_consultants_count_percent}
                />
              </div>
            </div>

          </div>
        </div>
        <div className="user-cash-form__footer">
          <button type="submit" className='user-cash-submit-button'>Сохранить изменения</button>
        </div>
      </form>
    </section>
  )
}