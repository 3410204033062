import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import refreshPage from '../../helpers/refreshPage/refreshPage';
import "./auth.css";


const URL_API = 'https://partner-system.ru/api/auth/jwt/create/';
const URL_API_REFRESH = 'https://partner-system.ru/api/auth/jwt/refresh/';


export default function Auth() {
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const loginHandleChange = (evt) => {
    evt.preventDefault();
    setLogin(evt.target.value);
  };

  const passwordHandleChange = (evt) => {
    evt.preventDefault();
    setPassword(evt.target.value);
    if (evt.target.value === 'superpass') {
      navigate('admin')
    }
  };

  function saveLocalStorage(response) {
    const ACCESS_TOKEN = response.data.access;
    const REFRESH_TOKEN = response.data.refresh;
    localStorage.setItem('ACCESS_TOKEN', ACCESS_TOKEN);
    localStorage.setItem('REFRESH_TOKEN', REFRESH_TOKEN);
  }

  const authData = {
    username: login,
    password: password
  };

  async function sendAuthData() {
    await axios
      .post(URL_API,
        authData
      )
      .then((response) => {
        console.log(`Status code ${response.status}`);
        console.log(response.data);
        if(response.status === 200) {
          saveLocalStorage(response);
          navigate('main');
          refreshPage();
        }
      })
      .catch((err) => {
        console.log(`Error: ${err.message}`);
      });
  }

  function refreshAcessToken() {
    const refresh_token = localStorage.getItem('REFRESH_TOKEN');
    if (refresh_token) {
      axios
        .post(URL_API_REFRESH, { refresh: refresh_token })
        .then((response) => {
          console.log(`Status code ${response.status}`);
          console.log(response.data);
          if (response.status === 200) {
            saveLocalStorage(response);
          }
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }

  useEffect(() => {
    const access_token = localStorage.getItem('ACCESS_TOKEN');
    const isAccessTokenExpired = isTokenExpired(access_token);
    if (isAccessTokenExpired) {
      refreshAcessToken();
    }
  });

  return (
    <div className='auth auth__wrapper'>
      <div className='auth__logo'>
        <img src="img/main-logo.svg" alt='logo'/>
      </div>
      <div className='auth__content'>
        <div className='auth__content-header'>
            Вход в личный кабинет
        </div>
        <div className='auth__content-user'>
          <form action='#' method='#' className='auth-from'>
            <input className='auth__content-input' onInput={loginHandleChange} type='text' value={login} placeholder='Логин' />
            <input className='auth__content-input' onInput={passwordHandleChange} type='text' value={password} placeholder='Пароль' />
            <button className='auth__content-button' onClick={sendAuthData}>Войти</button>
          </form>
        </div>
      </div>
    </div>
  )
}

function isTokenExpired(token) {
  if(token) {
    const decodedToken = parseJwt(token);
    const currentTimestamp = Math.floor(Date.now() / 1000);
    return decodedToken.exp < currentTimestamp;
  }
  return true;
}

function parseJwt(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );
  return JSON.parse(jsonPayload);
}
