import TopThreeCustomer from "../../components/topThreeCustomer/topThreeCustomer";
import CabinetHeader from "../../components/cabinet-header/cabinet-header";
import TabMenu from "../../components/navMenu/TabMenu";

export default function MainPage(): JSX.Element {

  return (
    <div>
      <CabinetHeader />
      <TopThreeCustomer />
      <TabMenu />
    </div>
  );
}
