import React from "react";
import {Link} from "react-router-dom";
import WalletHeader from "../walletHeader/WalletHeader";
import { Button } from "@mui/base";


export default function CabinetHeader() {
  return (
    <header className="main-header">
      <div className="main-logo">
        <img src="img/main-logo.svg" alt="main-logo"/>
      </div>
      <h1 className="main-header__title">
        Партнёрский кабинет
      </h1>
      <WalletHeader />
      <div className="main-header__profile">
        <img className="profile-title-icon" src="img/none_profile_icon.svg" alt="profile-acion"/>
        <Link className="profile-title" to="/profile">Профиль</Link>
      </div>
      <div>
        <Link to='/'>
          <Button className="main-header__exit-button trans-btn">Выйти</Button>
        </Link>
      </div>
    </header>
  );
}
