import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import MainInfo from '../../pages/main-info/main-info';
import MainClients from '../../pages/main-clients/main-clients';
import PartnerCode from '../partnerCode/PartnerCode';
import MainTopN from '../../pages/main-top-partners/MainTopN';
// import MainInfo from '../../pages/main-info/MainInfo';


interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index = 0, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const URL_API_TOP_MY_LEVEL = 'https://partner-system.ru/api/general/top_my_level_users/';
const ACCESS_TOKEN_USER = localStorage.getItem('ACCESS_TOKEN');

export default function TabMenu() {
  const [value, setValue] = useState(0);
  const [data, setData] = useState<Array<any>>([]);

  useEffect(() => {
    const selectedItem = localStorage.getItem('selectedItem');
    setValue(Number(selectedItem));
    fetchData();
  }, [])

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    localStorage.setItem('selectedItem', JSON.stringify(newValue));
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(URL_API_TOP_MY_LEVEL, {
        headers: {
          'Authorization': `Bearer ${ACCESS_TOKEN_USER}`
        }
      });
      const level = response.data['level'];
      setData(level);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Box sx={{ width: '100%', marginTop: "2" }}>
      <Box sx={{ borderBottom: 'none' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="secondary tabs example"
          centered
          TabIndicatorProps={{ sx: { backgroundColor: "#B63940", boxShadow: "0 1px 5px 0 #000000" } }}
          sx={{
            "& button": {borderRadius: 2, backgroundColor: 'none'},
            "& button:active": {},
            "& .MuiTab-root": { color: 'white', margin: "0 40px", textTransform: 'none'},
            "& .MuiTab-root.Mui-selected": { color: 'white' },
            "& .css-8je8zh-MuiTouchRipple-root":  { top: "100% !important"}
          }}
        >
          <Tab label="Общая информация" {...a11yProps(0)} />
          <Tab label="Клиенты и субпартнеры" {...a11yProps(1)} />
          <Tab label="Код партнера" {...a11yProps(2)} />
          <Tab label={`ТОП ${data}ов`}{...a11yProps(3)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <MainInfo/>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <MainClients/>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <PartnerCode/>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        <MainTopN />
      </CustomTabPanel>
    </Box>
  );
}