import React from "react";
import './profile-header.css'
import {Link} from "react-router-dom";


export default function ProfileHeader() {
  return (
    <header className="main-header">
      <Link to="/main" className="main-logo">
        <img src="img/main-logo.svg" alt="Парсинг и рассылка"/>
      </Link>
      <h1 className="main-header__title">
        Партнёрский кабинет
      </h1>
      <div className="height-corrector"></div>
      <div className="main-header__profile">
        <img src="img/none_profile_icon.svg" alt='профиль'/>
        <span className="profile-header__title">Профиль</span>
      </div>
      <div>
        <Link to="/">
          <button className="main-header__exit-button">Выйти</button>
        </Link>
      </div>
    </header>
  );
}
